import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/bestpractice/bestpractice/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "technology-choices"
    }}>{`Technology choices`}</h1>
    <p>{`Document about the tech stack used for developing code in the City of
Helsinki.`}</p>
    <h2 {...{
      "id": "backend-services"
    }}>{`Backend services`}</h2>
    <ul>
      <li parentName="ul">{`For most things: Python 3 with Django.`}<ul parentName="li">
          <li parentName="ul">{`Suggested versions for the new projects are Python 3.7 and Django 2.2 LTS.`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.django-rest-framework.org/"
            }}>{`Django REST framework`}</a>{` for REST APIs`}</li>
          <li parentName="ul">{`Graphene for GraphQL APIs, using `}<a parentName="li" {...{
              "href": "https://docs.graphene-python.org/projects/django/en/latest/"
            }}>{`Graphene-Django`}</a>{`.`}</li>
          <li parentName="ul">{`Postgres / PostGIS for background databases`}</li>
        </ul></li>
      <li parentName="ul">{`For stateless GraphQL proxies / API gateways:`}<ul parentName="li">
          <li parentName="ul">{`Node.js with Apollo`}</li>
        </ul></li>
      <li parentName="ul">{`For serving static files of React apps:`}<ul parentName="li">
          <li parentName="ul">{`Node.js with express (and server-side rendering)`}</li>
        </ul></li>
      <li parentName="ul">{`Make all APIs available through api.hel.fi`}</li>
    </ul>
    <h2 {...{
      "id": "frontend-services"
    }}>{`Frontend services`}</h2>
    <ul>
      <li parentName="ul">{`For most things: React with "standard" extensions (= just use
create-react-app).`}<ul parentName="li">
          <li parentName="ul">{`preferably TypeScript`}</li>
          <li parentName="ul">{`yarn and webpack`}</li>
          <li parentName="ul">{`redux for state`}</li>
          <li parentName="ul">{`Always set up and use server-side rendering`}</li>
        </ul></li>
      <li parentName="ul">{`always use components from Helsinki Design System
(`}<a parentName="li" {...{
          "href": "https://github.com/City-of-Helsinki/helsinki-design-system"
        }}>{`https://github.com/City-of-Helsinki/helsinki-design-system`}</a>{`).`}<ul parentName="li">
          <li parentName="ul">{`Contribute your components into HDS and use them by including the
package as a dependency`}</li>
        </ul></li>
      <li parentName="ul">{`Usually, the front pages of citizen directed services should be made
with Wordpress.  (So that they can be changed without coder
intervention)`}<ul parentName="li">
          <li parentName="ul">{`don't customise wordpress if you can avoid it`}<ul parentName="li">
              <li parentName="ul">{`if you can't, adhere to their coding standards to try to make the
code work across upgrades`}</li>
            </ul></li>
        </ul></li>
    </ul>
    <h2 {...{
      "id": "creating-libraries"
    }}>{`Creating Libraries`}</h2>
    <ul>
      <li parentName="ul">{`use npm and PyPI for distribution`}</li>
      <li parentName="ul">{`always include own libraries via npm/PyPI, never directly via version
control / local installation`}</li>
    </ul>
    <h2 {...{
      "id": "helpers--tooling"
    }}>{`Helpers & tooling`}</h2>
    <ul>
      <li parentName="ul">{`docker (and Dockerfiles) for creating runnable, publishable images`}<ul parentName="li">
          <li parentName="ul">{`make the image so that `}<inlineCode parentName="li">{`docker run XXX`}</inlineCode>{` (with environment variables)
brings up a working service`}</li>
          <li parentName="ul">{`test, stage, and production environments are always installed by the
Docker images in dockerhub`}</li>
          <li parentName="ul">{`docker-compose for reproducibly bringing up dev environments`}</li>
        </ul></li>
      <li parentName="ul">{`Use bash (for simple tasks) or Python 3 (for complex ones).`}<ul parentName="li">
          <li parentName="ul">{`if it has more than one loop, or any data processing, it's complex`}</li>
        </ul></li>
      <li parentName="ul">{`Gitlab or Travis for build, test and deployment automation`}<ul parentName="li">
          <li parentName="ul">{`the build instructions should be one command only.  If it's multiple
commands, put it in a script.`}</li>
        </ul></li>
      <li parentName="ul">{`Ansible for remote configuration of hosts`}</li>
      <li parentName="ul">{`Terraform for remote configuration of deployment environments`}</li>
      <li parentName="ul">{`Helm for remote configuration of Kubernetes`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      